<template>
    <div class="cartao-dinamico d-flex flex-column justify-content-between card_dinamico" @click="$emit('click')">
        <div class="d-flex flex-column justify-content-center align-items-center h-100 card_item">
            <div v-if="icon_bootstrap === 'vazio'">
                <i :class="classe" style="font-size:xx-large"></i>
            </div>
            <div v-else>
                <b-icon :icon="icon_bootstrap" scale="2"></b-icon>
            </div>
            <p id="cartao-titulo">{{titulo}}</p>
            <p id="cartao-subtitulo" class="m-0">{{subtitulo}}</p>
        </div>
        <div id="segura_t">
            <div class="cartao-barra-colorida" :style="{ backgroundColor: cor }">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CartaoDinamico',
        props: {
            classe: String,
            titulo: String,
            subtitulo: String,
            cor: String,
            icon_bootstrap: { String, default: 'vazio' },
            perfil: String,
            authorize: { type: Array, default: () => [] }
        }
    }
</script>

<style scoped>
    .cartao-dinamico {
        box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
        border-radius: 4px;
        background-color: #fff;
        width: 100%;
        word-break: break-all;
        text-align: center;
        cursor: pointer;
        height: 130px;
    }

        .cartao-dinamico:hover {
            background-color: #f5f5f5;
        }

    #cartao-titulo {
        font-weight: 500;
        margin: 10px 0 2px;
        font-size: 16px;
    }

    #cartao-titulo {
        text-transform: uppercase;
        font-weight: 500;
        margin: 10px 0 2px;
        font-size: 16px;
    }

    #cartao-subtitulo {
        font-size: small;
        color: #808080;
    }

    #icone {
        font-size: xx-large
    }

    .card_item {
        position: relative;
        top: 11px;
    }
     
    .card_dinamico:hover .cartao-barra-colorida {
        border-top: 20px solid #f2f2f2;
    }
    .cartao-barra-colorida {
        /* border-radius: 0px 0px 4px 4px;
        height: 15px;*/
        border-top: 20px solid #FFF;
        border-left: 20px solid transparent;
        float: right;
        transform: rotate( -90deg);
    }
</style>
